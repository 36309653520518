/**
 * @flow
 */

import { parseRawVacationRequestShift } from "./vacationRequestShift";
import {
  parseOptionalArrayFromSideloadMap,
  parseOptionalObjectFromSideloadMap,
} from "../../../../common/webpack/shared/utils/parsingUtils";

import type { VacationRequestShift } from "./vacationRequestShift";
import type { SideloadedMap } from "../../../../common/webpack/shared/utils/parsingUtils";
import { parseRawVacationRoundParticipant } from "./vacationRoundParticipant";
import type { VacationRoundParticipant } from "./vacationRoundParticipant";

export const ApprovalStatus = {
  Approved: ("approved": "approved"),
  Denied: ("denied": "denied"),
  Removed: ("removed": "removed"),
};

export const VacationHoursConflictStatus = {
  HoursWithinAvailability: ("hours_within_availability": "hours_within_availability"),
  HoursWithinThreshold: ("hours_within_threshold": "hours_within_threshold"),
  HoursExceeded: ("hours_exceeded": "hours_exceeded"),
};

export type ApprovalStatusType = $Values<typeof ApprovalStatus>;
export type VacationHoursConflictStatusType = $Values<typeof VacationHoursConflictStatus>;

// Warnings are shown even if the request is within the threshhold and therefore valid
export const shouldShowHoursConflictWarnings = (status: ?VacationHoursConflictStatusType): boolean => {
  return (
    status === VacationHoursConflictStatus.HoursExceeded || status === VacationHoursConflictStatus.HoursWithinThreshold
  );
};

// Errors are given only when the threshhold is passed
export const shouldShowHoursConflictErrors = (status: ?VacationHoursConflictStatusType): boolean =>
  status === VacationHoursConflictStatus.HoursExceeded;

export type RawVacationRequest = {
  id: number,
  vacation_round_participant: number,
  is_approved: boolean,
  is_denied: boolean,
  is_removed: boolean,
  list_rank: number,
  preference_index: number,
  start_date: string,
  end_date: string,
  employee_supplemental_consumed?: string,
  is_contained_in_an_active_eligibility_range: boolean,
  vacation_request_shifts: Array<number>,
  vacation_hours_conflict_status: VacationHoursConflictStatusType,
  vacation_hours_consumed: ?string,
  relief_necessary: boolean,
  created_on_behalf: boolean,
  processed_comment: string,
  vacation_hours_conflict_state_when_processed: VacationHoursConflictStatusType,
  was_over_quota_when_processed: ?boolean,
  overlaps_previously_approved_request: ?boolean,
  eligible_for_export_to_schedule_changes: ?boolean,
  resolution_method: ?[string, string],
};

export type RawVacationRequestForSubmission = {|
  list_rank: number,
  preference_index: number,
  start_date: string,
  end_date: string,
  employee_supplemental_consumed?: string,
  created_on_behalf?: boolean,
|};

export type VacationRequest = {
  id: number,
  vacationRoundParticipantID: number,
  vacationRoundParticipant: ?VacationRoundParticipant,
  isApproved: boolean,
  isDenied: boolean,
  isRemoved: boolean,
  approvalStatus: ?ApprovalStatusType,
  listRank: number,
  preferenceIndex: number,
  startDate: string,
  endDate: string,
  employeeSupplementalConsumed?: number,
  isContainedInAnActiveEligibilityRange: boolean,
  shiftIDs: Array<number>,
  shifts: ?Array<VacationRequestShift>,
  vacationHoursConflictStatus: ?VacationHoursConflictStatusType,
  vacationHoursConsumed: ?number,
  createdOnBehalf: boolean,
  processedComment: ?string,
  vacationHoursConflictStateWhenProcessed: ?VacationHoursConflictStatusType,
  wasOverQuotaWhenProcessed: ?boolean,
  overlapsPreviouslyApprovedRequest: ?boolean,
  eligibleForExportToScheduleChanges: ?boolean,
  resolutionMethod: ?string,
  displayResolutionMethod: ?string,
};

export type VacationRequestForSubmission = {
  id: ?number,
  vacationRoundParticipantID: number,
  isApproved: ?boolean,
  isDenied: ?boolean,
  isRemoved: ?boolean,
  isEligible: ?boolean,
  isDirtyChanged: boolean, // Are there any unsaved changes related to this request?
  listRank: number,
  preferenceIndex: number,
  startDate: string,
  endDate: string,
  employeeSupplementalConsumed?: number,
  createdOnBehalf?: boolean,
  processedComment: ?string,
  vacationHoursConsumed: ?number,
};

export const vacationRequestSortByListRankThenPreferenceIndex = (
  l: VacationRequest | VacationRequestForSubmission,
  r: VacationRequest | VacationRequestForSubmission
): number => l.listRank - r.listRank || l.preferenceIndex - r.preferenceIndex;

export const vacationRequestSortByPreferenceIndex = (
  l: VacationRequest | VacationRequestForSubmission,
  r: VacationRequest | VacationRequestForSubmission
): number => l.preferenceIndex - r.preferenceIndex;

export const serializeVacationRequestForSubmission = (
  requestForSubmission: VacationRequestForSubmission
): RawVacationRequestForSubmission => {
  let rawRequestForSubmission = {
    list_rank: requestForSubmission.listRank,
    preference_index: requestForSubmission.preferenceIndex,
    start_date: requestForSubmission.startDate,
    end_date: requestForSubmission.endDate,
    created_on_behalf: requestForSubmission.createdOnBehalf,
  };
  if (requestForSubmission.employeeSupplementalConsumed) {
    rawRequestForSubmission = {
      ...rawRequestForSubmission,
      employee_supplemental_consumed: requestForSubmission.employeeSupplementalConsumed.toString(10),
    };
  }
  return rawRequestForSubmission;
};

export const serializeVacationRequestForSubmissionArray = (
  requestForSubmissions: Array<VacationRequestForSubmission>
): Array<RawVacationRequestForSubmission> => requestForSubmissions.map(serializeVacationRequestForSubmission);

export const vacationRequestFromRaw = (
  rawRequest: RawVacationRequest,
  sideloadedMap: SideloadedMap
): VacationRequest => {
  const [resolutionMethod, displayResolutionMethod] = rawRequest.resolution_method || [null, null];
  return {
    id: rawRequest.id,
    vacationRoundParticipantID: rawRequest.vacation_round_participant,
    vacationRoundParticipant: parseOptionalObjectFromSideloadMap(
      sideloadedMap,
      "vacation_round_participants",
      rawRequest.vacation_round_participant,
      parseRawVacationRoundParticipant
    ),
    isApproved: rawRequest.is_approved,
    isDenied: rawRequest.is_denied,
    isRemoved: rawRequest.is_removed,
    approvalStatus: rawRequest.is_approved
      ? ApprovalStatus.Approved
      : rawRequest.is_denied
      ? ApprovalStatus.Denied
      : rawRequest.is_removed
      ? ApprovalStatus.Removed
      : null,
    listRank: rawRequest.list_rank,
    preferenceIndex: rawRequest.preference_index,
    startDate: rawRequest.start_date,
    endDate: rawRequest.end_date,
    employeeSupplementalConsumed: parseFloat(rawRequest.employee_supplemental_consumed),
    isContainedInAnActiveEligibilityRange: rawRequest.is_contained_in_an_active_eligibility_range,
    shiftIDs: rawRequest.vacation_request_shifts,
    shifts: parseOptionalArrayFromSideloadMap(
      sideloadedMap,
      "vacation_request_shifts",
      rawRequest.vacation_request_shifts,
      parseRawVacationRequestShift
    ),
    vacationHoursConflictStatus: rawRequest.vacation_hours_conflict_status,
    vacationHoursConsumed: parseFloat(rawRequest.vacation_hours_consumed),
    createdOnBehalf: rawRequest.created_on_behalf,
    processedComment: rawRequest.processed_comment.trim() || null,
    vacationHoursConflictStateWhenProcessed: rawRequest.vacation_hours_conflict_state_when_processed,
    wasOverQuotaWhenProcessed: rawRequest.was_over_quota_when_processed,
    overlapsPreviouslyApprovedRequest: rawRequest.overlaps_previously_approved_request,
    resolutionMethod,
    displayResolutionMethod,
    eligibleForExportToScheduleChanges: rawRequest.eligible_for_export_to_schedule_changes,
  };
};

export const vacationRequestsFromRaw = (
  rawRequests: Array<RawVacationRequest>,
  sideloadedMap: SideloadedMap
): Array<VacationRequest> => rawRequests.map((r) => vacationRequestFromRaw(r, sideloadedMap));
